export function DcContentLinkNode() {
    return {
        content: "block",
        group: "block",
        defining: true,
        attrs: {
            value: {}
        },
        nodeSize: 1,
        draggable: true,
        atom: true,
        parseDOM: [
            {
                tag: "dc-content-link[data-dc-content-link]",
                getAttrs: function (dom) {
                    var value = JSON.parse(dom.getAttribute("data-dc-content-link"));
                    return {
                        value: value
                    };
                }
            }
        ],
        toDOM: function (node) {
            var value = node.attrs.value;
            return [
                "dc-content-link",
                { "data-dc-content-link": JSON.stringify(value) }
            ];
        }
    };
}
