// tslint:disable-next-line
var Node = require("prosemirror-model").Node;
var MarkdownBlock = /** @class */ (function () {
    function MarkdownBlock(options, markdownLanguage) {
        this.options = options;
        this.markdownLanguage = markdownLanguage;
    }
    MarkdownBlock.prototype.canSerialize = function (schema, node) {
        return this.markdownLanguage.canSerializeNodeToMarkdown(schema, node);
    };
    MarkdownBlock.prototype.serialize = function (schema, nodes) {
        var parsedNodes = Node.fromJSON(schema, {
            type: "doc",
            content: nodes
        });
        return [
            {
                type: "markdown",
                data: this.markdownLanguage.serializeMarkdown(parsedNodes)
            }
        ];
    };
    MarkdownBlock.prototype.canParse = function (schema, block) {
        return block.type === "markdown";
    };
    MarkdownBlock.prototype.parse = function (schema, blocks) {
        var _this = this;
        var result = [];
        blocks.forEach(function (block) {
            var doc = _this.markdownLanguage
                .parseMarkdown(block.data || "")
                .toJSON();
            result = result.concat.apply(result, doc.content || []);
        });
        return result;
    };
    return MarkdownBlock;
}());
export default MarkdownBlock;
