export function DcImageLinkNode() {
    return {
        content: "block",
        group: "block",
        defining: true,
        attrs: {
            value: {}
        },
        nodeSize: 1,
        draggable: true,
        atom: true,
        parseDOM: [
            {
                tag: "dc-image-link[data-dc-image-link]",
                getAttrs: function (dom) {
                    var value = JSON.parse(dom.getAttribute("data-dc-image-link"));
                    return {
                        value: value
                    };
                }
            }
        ],
        toDOM: function (node) {
            var value = node.attrs.value;
            return ["dc-image-link", { "data-dc-image-link": JSON.stringify(value) }];
        }
    };
}
