var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function rangeHasFilteredMark(doc, from, to, type, filter) {
    var found = false;
    if (to > from) {
        doc.nodesBetween(from, to, function (node) {
            if (type.isInSet(node.marks) && node.marks.filter(filter).length > 0) {
                found = true;
            }
            return !found;
        });
    }
    return found;
}
export function isFilteredMarkActive(state, type, filter) {
    var _a = state.selection, from = _a.from, $from = _a.$from, to = _a.to, empty = _a.empty;
    if (empty) {
        if (!type.isInSet(state.storedMarks || $from.marks())) {
            return false;
        }
        var validMarks = (state.storedMarks || $from.marks()).filter(filter);
        return validMarks.length > 0;
    }
    else {
        return rangeHasFilteredMark(state.doc, from, to, type, filter);
    }
}
function getMarkFromRange(state, from, to, markType) {
    var result;
    if (to > from) {
        state.doc.nodesBetween(from, to, function (node) {
            if (markType.isInSet(node.marks)) {
                result = node.marks.find(function (x) { return x.type === markType; });
                return false;
            }
            return true;
        });
    }
    return result;
}
function getStoredMark(state, markType) {
    var ref = state.selection;
    var $cursor = ref.$cursor;
    if ($cursor) {
        return (state.storedMarks || $cursor.marks()).find(function (x) { return x.type === markType; });
    }
}
function mergeAttrs(oldAttrs, newAttrs) {
    return __assign(__assign({}, oldAttrs), newAttrs);
}
export function setMarkAttributes(markType, attrs) {
    return function (state, dispatch) {
        var ref = state.selection;
        var $cursor = ref.$cursor;
        var ranges = ref.ranges;
        if (dispatch) {
            if ($cursor) {
                if (markType.isInSet(state.storedMarks || $cursor.marks())) {
                    var existingMark = getStoredMark(state, markType);
                    dispatch(state.tr.removeStoredMark(markType));
                    if (existingMark) {
                        dispatch(state.tr.addStoredMark(markType.create(mergeAttrs(existingMark.attrs, attrs))));
                    }
                }
                else {
                    dispatch(state.tr.addStoredMark(markType.create(attrs)));
                }
            }
            else {
                var has = false;
                var tr = state.tr;
                for (var i = 0; !has && i < ranges.length; i++) {
                    var ref$1 = ranges[i];
                    var $from = ref$1.$from;
                    var $to = ref$1.$to;
                    has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
                }
                for (var _i = 0, ranges_1 = ranges; _i < ranges_1.length; _i++) {
                    var ref$2 = ranges_1[_i];
                    var $from$1 = ref$2.$from;
                    var $to$1 = ref$2.$to;
                    if (has) {
                        var markAttrs = __assign({}, getMarkFromRange(state, $from$1.pos, $to$1.pos, markType).attrs);
                        tr.removeMark($from$1.pos, $to$1.pos, markType);
                        tr.addMark($from$1.pos, $to$1.pos, markType.create(mergeAttrs(markAttrs, attrs)));
                    }
                    else {
                        tr.addMark($from$1.pos, $to$1.pos, markType.create(attrs));
                    }
                }
                dispatch(tr.scrollIntoView());
            }
        }
        return true;
    };
}
