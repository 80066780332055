export var AnchorToMarkdown = {
    anchor: function (state, node) {
        var attrs = {};
        if (node.attrs.value) {
            attrs.id = node.attrs.value;
        }
        var attrsSerialized = Object.keys(attrs)
            .map(function (key) { return key + "=\"" + attrs[key] + "\""; })
            .join(" ");
        state.write("<a " + attrsSerialized + "></a>");
    }
};
