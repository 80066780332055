// Process &shy;
export function soft_hyphen_from(state, silent) {
    var pos = state.pos;
    if (state.src.slice(pos, pos + 5) !== "&shy;") {
        return false;
    }
    if (!silent) {
        var content = state.src.slice(pos, pos + 5);
        var token = state.push("soft_hyphen", "shy", 0);
        token.attrs = [];
        token.content = content;
    }
    state.pos = pos + 5;
    return true;
}
