// tslint:disable-next-line
var markdown = require("prosemirror-markdown");
// tslint:disable-next-line
var Node = require("prosemirror-model").Node;
var DcContentLinkBlock = /** @class */ (function () {
    function DcContentLinkBlock() {
    }
    DcContentLinkBlock.prototype.canSerialize = function (schema, node) {
        return node.type === "dc-content-link";
    };
    DcContentLinkBlock.prototype.serialize = function (schema, nodes) {
        return nodes.map(function (node) {
            return {
                type: "dc-content-link",
                data: node.attrs.value
            };
        });
    };
    DcContentLinkBlock.prototype.canParse = function (schema, block) {
        return block.type === "dc-content-link";
    };
    DcContentLinkBlock.prototype.parse = function (schema, blocks) {
        var result = [];
        blocks.forEach(function (block) {
            result.push({
                type: "dc-content-link",
                attrs: {
                    value: block.data
                }
            });
        });
        return result;
    };
    return DcContentLinkBlock;
}());
export default DcContentLinkBlock;
