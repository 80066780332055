import React from 'react';
import ReactDOM from 'react-dom';
import { ChooserActions, DeleteIcon, StyledFab, withTheme } from 'unofficial-dynamic-content-ui';
import InlineChooser from '../InlineChooser/InlineChooser';
import { CircularProgress, withStyles } from '@material-ui/core';
// tslint:disable-next-line
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import clsx from 'clsx';
var styles = {
    root: {
        '&$broken, &$invalid': {
            background: '#e5e5e5'
        }
    },
    image: {
        maxWidth: '100%'
    },
    loading: {},
    broken: {},
    invalid: {},
    statusIcons: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        justifyContent: 'center'
    }
};
var ViewComponent = withStyles(styles)(function (props) {
    var _a;
    var node = props.node, onDelete = props.onDelete, classes = props.classes, options = props.options;
    var value = node.attrs.value;
    // BrokenImageIcon
    var _b = React.useState(false), broken = _b[0], setBroken = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var handleLoading = React.useCallback(function () {
        setLoading(true);
    }, [setLoading]);
    var handleError = React.useCallback(function () {
        setBroken(true);
        setLoading(false);
    }, [setBroken, setLoading]);
    var handleLoaded = React.useCallback(function () {
        setBroken(false);
        setLoading(false);
    }, [setBroken, setLoading]);
    var hasValidValue = value && value.endpoint && value.name;
    //Workaround for ts-jest
    var Fab = StyledFab;
    return (React.createElement("div", { className: clsx(classes.root, (_a = {},
            _a[classes.broken] = broken,
            _a[classes.loading] = loading,
            _a[classes.invalid] = !hasValidValue,
            _a)) },
        React.createElement(InlineChooser, { variant: "populated-slot", aspectRatio: broken || loading || !hasValidValue ? '3:1' : undefined },
            hasValidValue ?
                (React.createElement("img", { className: classes.image, src: "//" + (options && options.dynamicContent && options.dynamicContent.stagingEnvironment ? options.dynamicContent.stagingEnvironment : value.defaultHost) + "/i/" + value.endpoint + "/" + encodeURIComponent(value.name), onLoadStart: handleLoading, onError: handleError, onLoad: handleLoaded, alt: "" })) : false,
            React.createElement("div", { className: classes.statusIcons }, loading ?
                React.createElement(CircularProgress, null) :
                (broken || !hasValidValue ? React.createElement(BrokenImageIcon, { fontSize: "large" }) : false)),
            React.createElement(ChooserActions, { variant: "populated-slot" },
                React.createElement(Fab, { variant: "dark", onClick: onDelete }, DeleteIcon)))));
});
var DcImageLinkView = /** @class */ (function () {
    function DcImageLinkView(node, view, getPos, options) {
        if (options === void 0) { options = {}; }
        this.node = node;
        this.view = view;
        this.getPos = getPos;
        this.options = options;
        this.dom = document.createElement('div');
        this.handleDelete = this.handleDelete.bind(this);
        this.render();
    }
    DcImageLinkView.prototype.handleDelete = function () {
        var start = this.getPos();
        var tr = this.view.state.tr.delete(start, start + this.node.nodeSize);
        this.view.dispatch(tr);
    };
    DcImageLinkView.prototype.render = function () {
        ReactDOM.render(withTheme(React.createElement(ViewComponent, { options: this.options, node: this.node, onDelete: this.handleDelete })), this.dom);
    };
    return DcImageLinkView;
}());
export { DcImageLinkView };
