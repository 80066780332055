var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight } from "@material-ui/icons";
import React from "react";
function getContainingTextBlocks(state) {
    var resultNodes = new Set();
    var result = [];
    state.doc.nodesBetween(state.selection.from, state.selection.to, function (node, pos, parent, index) {
        if (node.isTextblock && !resultNodes.has(node)) {
            result.push({ node: node, pos: pos, start: 0, depth: 0 });
            resultNodes.add(node);
        }
    });
    return Array.from(result);
}
function align(state, dispatch, alignment) {
    // Apply align attr to the text block.
    var result = getContainingTextBlocks(state);
    var tr = state.tr;
    result.forEach(function (item) {
        var node = item.node, pos = item.pos;
        tr = tr.setNodeMarkup(pos, node.type, __assign(__assign({}, node.attrs), { align: alignment }));
    });
    if (dispatch) {
        dispatch(tr);
    }
    return true;
}
function alignActive(state, view, alignment) {
    // Apply align attr to the text block.
    var result = getContainingTextBlocks(state);
    var anyMismatch = false;
    result.forEach(function (item) {
        if ((result[0].node.attrs.align || "left") !== alignment) {
            anyMismatch = true;
        }
    });
    if (result.length === 0) {
        return false;
    }
    return !anyMismatch;
}
function alignable(state, view) {
    // Any text block is alignable.
    return getContainingTextBlocks(state).length > 0;
}
export function AlignLeftTool(schema) {
    return {
        name: "align_left",
        label: "Align Left",
        displayIcon: React.createElement(FormatAlignLeft, null),
        apply: function (state, dispatch) { return align(state, dispatch, "left"); },
        isActive: function (state, view) { return alignActive(state, view, "left"); },
        isVisible: alignable,
    };
}
export function AlignCenterTool(schema) {
    return {
        name: "align_center",
        label: "Align center",
        displayIcon: React.createElement(FormatAlignCenter, null),
        apply: function (state, dispatch) { return align(state, dispatch, "center"); },
        isActive: function (state, view) { return alignActive(state, view, "center"); },
        isVisible: alignable,
    };
}
export function AlignRightTool(schema) {
    return {
        name: "align_right",
        label: "Align Right",
        displayIcon: React.createElement(FormatAlignRight, null),
        apply: function (state, dispatch) { return align(state, dispatch, "right"); },
        isActive: function (state, view) { return alignActive(state, view, "right"); },
        isVisible: alignable,
    };
}
export function AlignJustifyTool(schema) {
    return {
        name: "align_justify",
        label: "Align Justify",
        displayIcon: React.createElement(FormatAlignJustify, null),
        apply: function (state, dispatch) { return align(state, dispatch, "justify"); },
        isActive: function (state, view) { return alignActive(state, view, "justify"); },
        isVisible: alignable,
    };
}
