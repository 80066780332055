/**
 * Non-standard tool for applying a css class name to a region of the document
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// tslint:disable-next-line
export var inline_styles = {
    attrs: {
        class: { default: "" },
        style: { default: "" }
    },
    group: "inline",
    parseDOM: [
        {
            tag: "span",
            getAttrs: function (node) {
                return {
                    class: node.getAttribute("class"),
                    style: node.attributes.style ? node.attributes.style.value : undefined
                };
            }
        }
    ],
    toDOM: function (mark) {
        return [
            "span",
            __assign(__assign({}, (mark.attrs.style ? { style: mark.attrs.style } : {})), { class: mark && mark.attrs && mark.attrs.class ? mark.attrs.class : "" }),
            0
        ];
    }
};
