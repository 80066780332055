export function inBlock(state) {
    var $from = state.selection.$from;
    return $from.depth > 1;
}
export function isMarkActive(state, type) {
    var _a = state.selection, from = _a.from, $from = _a.$from, to = _a.to, empty = _a.empty;
    if (empty) {
        return type.isInSet(state.storedMarks || $from.marks());
    }
    else {
        return state.doc.rangeHasMark(from, to, type);
    }
}
export function getSelectionMarks(state) {
    var _a = state.selection, $from = _a.$from, $to = _a.$to;
    var currentMarks = [];
    state.doc.nodesBetween($from.pos, $to.pos, function (node, start) {
        node.marks.forEach(function (mark) {
            return currentMarks.push({ from: start, to: start + node.nodeSize, mark: mark });
        });
    });
    return currentMarks;
}
export function hasMarkup(state, type, atts) {
    var _a = state.selection, $from = _a.$from, to = _a.to, node = _a.node;
    if (node) {
        return node.hasMarkup(type, atts);
    }
    return to <= $from.end() && $from.parent.hasMarkup(type, atts);
}
export function canInsert(state, type) {
    var $from = state.selection.$from;
    for (var d = $from.depth; d >= 0; d--) {
        var index = $from.index(d);
        if ($from.node(d).canReplaceWith(index, index, type)) {
            return true;
        }
    }
    return false;
}
export function clearAllMarks() {
    return function (state, dispatch) {
        var ref = state.selection;
        var $cursor = ref.$cursor;
        var ranges = ref.ranges;
        if (dispatch) {
            if ($cursor) {
                var marks = state.storedMarks || $cursor.marks();
                for (var _i = 0, marks_1 = marks; _i < marks_1.length; _i++) {
                    var mark = marks_1[_i];
                    dispatch(state.tr.removeStoredMark(mark.type));
                }
            }
            else {
                var tr = state.tr;
                for (var _a = 0, ranges_1 = ranges; _a < ranges_1.length; _a++) {
                    var range = ranges_1[_a];
                    for (var _b = 0, _c = Object.keys(state.schema.marks); _b < _c.length; _b++) {
                        var markName = _c[_b];
                        tr.removeMark(range.$from.pos, range.$to.pos, state.schema.marks[markName]);
                    }
                }
                dispatch(tr);
            }
        }
        return true;
    };
}
