export var InlineStylesToMarkdown = {
    inline_styles: {
        open: function (state, mark, parent, index) {
            var attrs = {};
            if (mark.attrs.class) {
                attrs.class = mark.attrs.class;
            }
            var attrsSerialized = Object.keys(attrs)
                .map(function (key) { return key + "=\"" + attrs[key] + "\""; })
                .join(" ");
            if (attrsSerialized.length > 0) {
                return "<span " + attrsSerialized + ">";
            }
            else {
                return "<span>";
            }
        },
        close: "</span>"
    }
};
