// tslint:disable-next-line
export var anchor = {
    group: "inline",
    inline: true,
    attrs: {
        value: {}
    },
    parseDOM: [
        {
            tag: "a[id]",
            getAttrs: function (dom) {
                var value = JSON.parse(dom.getAttribute("id"));
                return {
                    value: value
                };
            }
        }
    ],
    toDOM: function (node) {
        var value = node.attrs.value;
        return ["a", { id: JSON.stringify(value) }, value];
    }
};
