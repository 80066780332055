import { isToolEnabled } from "@dc-extension-rich-text/common";
import React from 'react';
import { isFilteredMarkActive, setMarkAttributes } from "../utils/MarkUtils";
export function inlineStyleClassNameTool(schema, className, classLabel) {
    var mark = schema.marks.inline_styles;
    return {
        name: "inline_styles_className_" + className,
        label: classLabel,
        displayLabel: React.createElement("span", { className: className }, classLabel),
        apply: setMarkAttributes(mark, { class: className }),
        isEnabled: function (state) { return true; },
        isActive: function (state) { return isFilteredMarkActive(state, mark, function (m) { return m.attrs.class === className; }); },
    };
}
export function createInlineStylesTools(schema, options) {
    var tools = [];
    if (schema.marks.inline_styles && isToolEnabled('inline_styles', options) && options.tools && options.tools.inline_styles) {
        var inlineStylesOptions = options.tools.inline_styles;
        if (inlineStylesOptions.classNames) {
            for (var _i = 0, _a = inlineStylesOptions.classNames; _i < _a.length; _i++) {
                var className = _a[_i];
                tools.push(inlineStyleClassNameTool(schema, className.className, className.label));
            }
        }
    }
    return tools;
}
